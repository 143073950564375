<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0"><b>Paciente: </b> {{ atendimento.paciente }}
              <br />
              <b>Edad: </b> {{ idade }}
            </h4>

            <h4 class="pull-right">

              <b class="ml-1 pull-right">{{ $t("FECHA.NOME") }}</b>
              {{ atendimento.created_at | formatDBDateToLocale }}
              <br />
              <b class="mr-1  pull-right">{{ $t("ESTADO.NOME") }}</b>
              <span class="label label-lg label-inline px-3 h5" :class="{
                'label-light-success ': atendimento.status === 1,
                'label-light-danger': atendimento.status === 0,
                'label-light': atendimento.status === 2,
                'label-light-info': atendimento.status === 3,
                'label-light-info': atendimento.status === 4,
                'label-light-primary': atendimento.status === 5,
              }">
                {{
                  atendimento.status === 0
                    ? "Eliminado"
                    : atendimento.status === 1
                      ? "Abierto"
                      : atendimento.status === 2
                        ? "Cerrado"
                        : atendimento.status === 3
                          ? "Aguardando Triaje"
                          : atendimento.status === 4
                            ? "Aguardando Consulta"
                            : atendimento.status === 5
                              ? "Consulta Hecha"
                              : "Finalizado"
                }}
              </span>
              <button @click="chamar_paciente(atendimento)" v-if="atendimento.status == 3 || atendimento.status == 4"
                v-show="lista_permissoes_filial.u_Atendimento" class="btn btn-icon btn-light btn-sm mx-1 mb-2"
                v-b-tooltip.hover title="Llamar en la pantalha de turnos">
                <i class="fas fa-volume-up text-info"></i>
              </button>
            </h4>



          </div>
          <h4 class="mb-0"></h4>
        </div>
      </div>
    </div>

    <div class="col-md-4 mt-2">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between align-items-center">
            <span class="mt-2 h2">
              <b>{{ $t("OCORRENCIAS.NOME") }}</b>
            </span>

            <b-dropdown size="sm" variant="link"
              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right
              no-flip>
              <template v-slot:button-content>
                <i class="ki ki-bold-more-ver"></i>
              </template>
              <!--begin::Navigation-->
              <div class="navi navi-hover min-w-md-250px">
                <b-dropdown-text tag="div" class="navi-item">
                  <a @click="showModalOcorrencia('Triagem')" class="btn btn-outline-info btn-block">
                    <span class="navi-icon">
                      <i class="flaticon2-cardiogram"></i>
                    </span>
                    <span class="navi-text">{{ $t("TRIAGEM.NOME") }}</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <a @click="showModalOcorrencia('Consulta')" class="btn btn-outline-success btn-block">
                    <span class="navi-icon">
                      <i class="fas fa-stethoscope"></i>
                    </span>
                    <span class="navi-text">Consulta</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <a @click="showModalOcorrencia('Receita')" class="btn btn-outline-primary btn-block">
                    <span class="navi-icon">
                      <i class="flaticon2-writing"></i>
                    </span>
                    <span class="navi-text">{{ $t("RECEITA.NOME") }}</span>
                  </a>
                </b-dropdown-text>
                <b-dropdown-text tag="div" class="navi-item">
                  <a @click="getSaidasPaciente()" v-b-tooltip.hover class="btn btn-outline-secondary btn-block"
                    title="Insumos y Productos utilizados en el paciente">
                    <span class="navi-icon">
                      <i class="flaticon-logout"></i>
                    </span>
                    <span class="navi-text">Salidas</span>
                  </a>
                </b-dropdown-text>
              </div>
              <!--end::Navigation-->
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="card mt-2 full-height bg-ligth">
        <div class="card-body">
          <template v-for="(item, i) in lista_ocorrencias_atendimentos">
            <!--begin::Item-->
            <div id="exemplo1" @click="escolher(item)" class="d-flex align-items-center mb-10" v-bind:key="i">
              <!--begin::Bullet-->
              <span class="bullet bullet-bar align-self-stretch" v-bind:class="`bg-${item.tipo_ocorrencia.id == 1
                ? 'info'
                : item.tipo_ocorrencia.id == 2
                  ? 'primary'
                  : 'success'
                }`"></span>

              <label class="
                  checkbox checkbox-lg checkbox-single
                  flex-shrink-0
                  m-0
                  mx-4
                " v-bind:class="`checkbox-light-${item.tipo_ocorrencia.id == 1
                  ? 'info'
                  : item.tipo_ocorrencia.id == 2
                    ? 'primary'
                    : 'success'
                  }`">

                <span></span>
              </label>

              <div class="d-flex flex-column flex-grow-1">
                <a type="button" v-bind:class="`text-${item.tipo_ocorrencia.id == 1
                  ? 'info'
                  : item.tipo_ocorrencia.id == 2
                    ? 'primary'
                    : item.tipo_ocorrencia.id == 4
                      ? 'light'
                      : 'success'
                  }`" class="
                    text-dark-75 text-hover-primary
                    font-weight-bold font-size-lg
                    mb-1
                  ">
                  <span v-if="item.tipo_ocorrencia_id == 1">TRIAGE </span>
                  <span v-if="item.tipo_ocorrencia_id == 2">CONSULTA </span>
                  <span v-if="item.tipo_ocorrencia_id == 3">OBSERVACION </span>
                  <span v-if="item.tipo_ocorrencia_id == 4">PRESCRIPCIÓN MEDICA </span>
                </a>
                <span class="text-muted font-weight-bold">
                  {{ item.ocorrencia.created_at | formatDBDateToLocale }}
                </span>
              </div>
            </div>
          </template>
          <br />
        </div>
      </div>
    </div>

    <div class="col-md-8 mt-2">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="row justify-content-end">
            <div class="ml-1">
              <button v-b-tooltip.hover title="Imprimir Ocurrencia" class="btn btn-light-info"
                @click="showModalImpressoes(tipo_ocorrencia)">
                <i class="flaticon2-print m-0 p-0"></i>
              </button>
            </div>
            <div class="ml-4" v-if="ocorrencia_id">
              <button v-b-tooltip.hover title="Realizar Salida de Insumos o Medicamentos"
                @click="showModalOcorrencia('Saida')" class="btn btn-light-success">
                <i class="flaticon2-medical-records m-0 p-0"></i>
              </button>
            </div>
            <div class="ml-1">
              <button v-b-tooltip.hover title="Lista de salidas de insumos y medicamentos" @click="getSaidasPaciente()"
                class="btn btn-light-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capsule"
                  viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1.828 8.9 8.9 1.827a4 4 0 1 1 5.657 5.657l-7.07 7.071A4 4 0 1 1 1.827 8.9Zm9.128.771 2.893-2.893a3 3 0 1 0-4.243-4.242L6.713 5.429l4.243 4.242Z" />
                </svg>
                Salidas
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <triagem v-if="tipo_ocorrencia == 'Triagem'"></triagem>
          <consulta v-if="tipo_ocorrencia == 'Consulta'"></consulta>
          <receita v-if="tipo_ocorrencia == 'Receita'"></receita>
          <saida v-if="tipo_ocorrencia == 'Saida'"></saida>
          <ListSaidas v-if="tipo_ocorrencia == 'ListSaida'"></ListSaidas>
        </div>
      </div>
    </div>

    <b-modal v-model="modal_ocorrencia.show" :title="modal_ocorrencia.tipo_ocorrencia"
      :ref="modal_ocorrencia.tipo_ocorrencia" :size="modal_ocorrencia.size" centered hide-footer>
      <b-overlay :show="modal_ocorrencia.loading" rounded="sm">
        <div class="row justify-content-center">
          <triagem v-if="modal_ocorrencia.tipo_ocorrencia == 'Triagem'"></triagem>
          <consulta v-if="modal_ocorrencia.tipo_ocorrencia == 'Consulta'"></consulta>
          <receita v-if="modal_ocorrencia.tipo_ocorrencia == 'Receita'"></receita>
          <saida v-if="modal_ocorrencia.tipo_ocorrencia == 'Saida'"></saida>
        </div>
      </b-overlay>
    </b-modal>

    <b-modal v-model="modal_impressoes.show" :title="modal_impressoes.tipo_ocorrencia" size="lg" centered hide-footer>
      <b-overlay :show="modal_impressoes.loading" rounded="sm">
        <div class="row justify-content-center" style="background-color: #fff">
          <ImprTriagem v-if="modal_impressoes.tipo_ocorrencia == 'Triagem'"></ImprTriagem>
          <ImprAtendimento v-if="modal_impressoes.tipo_ocorrencia == 'Atendimento'"></ImprAtendimento>
          <ImprReceita v-if="modal_impressoes.tipo_ocorrencia == 'Receita'"></ImprReceita>
          <ImprConsulta v-if="modal_impressoes.tipo_ocorrencia == 'Consulta'"></ImprConsulta>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<style>
#exemplo1:hover {
  background-color: #f8f8ff;
}
</style>
<script>
import { getFilialId } from "../../../core/services/filial.service";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import Triagem from "@/view/components/ocorrencia/triagem.vue";
import Consulta from "@/view/components/ocorrencia/Consulta.vue";
import Receita from "@/view/components/ocorrencia/modalReceita.vue";
import Saida from "@/view/components/farmacia/saidaProduto/formSaida.vue";
import ListSaidas from "@/view/components/farmacia/saidaProduto/listSaidas.vue";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import dateFormat from "@/core/mixins/dateFormat";
import ImprTriagem from "@/view/components/ocorrencia/impressoes/triagem.vue";
import ImprAtendimento from "@/view/components/ocorrencia/impressoes/atendimento.vue";
import ImprReceita from "@/view/components/ocorrencia/impressoes/receita.vue";
import ImprConsulta from "@/view/components/ocorrencia/impressoes/consulta.vue";
export default {
  components: {
    Triagem,
    Consulta,
    Receita,
    Saida,
    ListSaidas,
    ImprTriagem,
    ImprAtendimento,
    ImprReceita,
    ImprConsulta,

  },
  mixins: [fireAlert, dateFormat],
  data() {
    return {
      form_triagem: {
        temperatura: "",
        peso: "",
        altura: "",
        pressao: "",
        primeiro_sintomas: "",
        motivo: "",
        frequencia: "",
        saturacao: "",
        grau_urgencia_id: "",
        tipo_ocorrencia_id: "",
        atendimento_id: "",
      },

      modal_ocorrencia: {
        show: false,
        loading: false,
        tipo_ocorrencia: "",
        size: 'lg',
        form: {},
      },
      modal_impressoes: {
        show: false,
        loading: false,
        tipo_ocorrencia: "",
        form: {},
      },
      tipo_ocorrencia: false,
      lista_escolhida: [],
      removido: [],
      currentPage: 1,
      perPage: 10,
      verif: false,
      ocorrencia_id: "",
      ocorrencia_impr: [],
      idade: null
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ocorrencias" }]);
  },
  created() {
    this.setatendimento();
  },
  computed: {
    lista_tipo_ocorrencia() {
      return this.$store.state.ocorrencia.lista_tipo_ocorrencia;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    dados_modais() {
      return this.$store.state.ocorrencia.dados_modais;
    },
    lista_grau_urgencia() {
      return this.$store.state.ocorrencia.lista_grau_urgencia;
    },
    lista_ocorrencias_atendimentos() {
      return this.$store.state.atendimento.lista_ocorrencias_atendimentos;
    },
    atendimento() {
      return this.$store.state.atendimento.atendimento;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.atendimento.mensagem_alert;
    },
    ocorrencia() {
      return this.$store.state.ocorrencia.ocorrencia;
    },
    lista_dados() {
      //diz se o que vai aparecer no card_body
      this.tipo_ocorrencia = "Saida";
      return this.$store.state.ocorrencia.lista_dados;
    },


  },
  watch: {
    ocorrencia() {
      this.lista_ocorrencias();
    },
    dados_modais() {
      console.log("aqui dados assistindo", this.dados_modais)
      if (this.dados_modais.fechar === true) {
        // this.modal_ocorrencia.tipo_ocorrencia = this.dados_modais.tipo
        this.modal_ocorrencia.show = false
        console.log("entrou")
      }
    }


  },
  methods: {
    async chamar_paciente(item) {
      console.log(item)
      let consultorio = "Consultorio"
      if (item.status == 3) {
        consultorio = "Triaje"
      } else if (item.consultorio != null) {
        consultorio = item.consultorio
      }
      const axios = require('axios');
      let data = JSON.stringify({
        "id": item.usuario_id,
        "status": 1,
        "tela_id": getFilialId(),
        "nome_sala": consultorio
      });

      console.log(data)

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://fila.pagwise.com/api/chamar-paciente',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    },
    calcularIdade() {
      if (this.atendimento.data_nascimento != null) {
        var hoje = new Date();
        var dataNascimento = new Date(this.atendimento.data_nascimento);
        // this.data_nascimento = dataNascimento.toLocaleDateString()
        var idade = hoje.getFullYear() - dataNascimento.getFullYear();
        var meses = hoje.getMonth() - dataNascimento.getMonth();
        if (hoje.getDate() < dataNascimento.getDate()) {
          meses--;
        }
        if (meses < 0 || (meses === 0 && hoje.getDate() < dataNascimento.getDate())) {
          idade--;
          meses = 12 + meses;
        }
        this.idade = ` ${idade} años y ${meses} meses`;
      }
    },
    listar_requerimentos() {
      this.$store.dispatch("ocorrencia/listar_tipo_ocorrencia");
      this.$store.dispatch("ocorrencia/listar_grau_urgencia");

    },

    showModalOcorrencia(name) {
      if (name == "Saida") {
        if (this.ocorrencia_id) {
          this.modal_ocorrencia.show = true;
          this.modal_ocorrencia.tipo_ocorrencia = name;
          this.$store.dispatch("configEmpresa/atualizar", this.ocorrencia_id);
        } else {
          this.fireAlert({
            title:
              "Selecione uma ocorrencia para realizar a retirada de medicamentos!",
            tipo: "error",
            routeName: "createOcorrencia",
            timer: 5000,
          });
        }
      } else {
        this.modal_ocorrencia.show = true;
        this.modal_ocorrencia.tipo_ocorrencia = name;
        if (name == 'Receita') { this.modal_ocorrencia.size = 'xl' } else {
          this.modal_ocorrencia.size = 'lg'
        }
        this.$store.dispatch("configEmpresa/atualizar", "");
      }
    },
    async escolher(value) {
      console.log(value);
      this.ocorrencia_id = value.id;
      this.ocorrencia_impr = value;

      // this.lista_escolhida = [value];
      // this.form_triagem = value.ocorrencia;
      // this.form_triagem.tipo_ocorrencia_id = value.tipo_ocorrencia.id;
      //    this.form_triagem.grau_urgencia_id = value.grau_urgencia.id;
      await this.$store.dispatch("configEmpresa/atualizar", value);
      var part = value.ocorrencia_type.split("\\");
      this.tipo_ocorrencia = part[2];
      // console.log(part);
      console.log(part[2]);
      // this.form_triagem.temperatura = this.lista_escolhida.triagem;
      // this.form_triagem.peso = this.lista_escolhida.peso;
      // this.form_triagem.altura = this.lista_escolhida.altura;
      // this.form_triagem.pressao = this.lista_escolhida.pressao;
      // this.form_triagem.primeiro_sintomas = this.lista_escolhida.primeiro_sintomas;
      // this.form_triagem.titulo = this.lista_escolhida.titulo;
      // this.form_triagem.grau_urgencia_id = this.lista_escolhida.grau_urgencia_id;
      // this.form_triagem.tipo_ocorrencia_id = this.lista_escolhida.tipo_ocorrencia_id;
      //  this.form_triagema.tendimento_id= this.lista_escolhida.tendimento_id
    },
    async showModalImpressoes(name) {
      if (this.ocorrencia_id) {
        this.modal_impressoes.show = true;
        this.modal_impressoes.tipo_ocorrencia = name;
        this.$store.dispatch("configEmpresa/atualizar", this.ocorrencia_impr);
        await this.$store.dispatch(
          "configUsuarios/listar_usuario",
          this.ocorrencia_impr.paciente_id
        );
      } else {
        this.modal_impressoes.show = true;
        this.modal_impressoes.tipo_ocorrencia = "Atendimento";
        this.$store.dispatch(
          "configEmpresa/atualizar",
          this.lista_ocorrencias_atendimentos
        );
      }
    },
    async getSaidasPaciente() {
      const { atendimento_id } = this.$route.params;
      this.tipo_ocorrencia = "ListSaida";
      await this.$store.dispatch("configEmpresa/atualizar", atendimento_id);
    },

    async setatendimento() {
      const { atendimento_id } = this.$route.params;
      this.$store.dispatch("configEmpresa/MudarPreloader", true);

      if (!atendimento_id) {
        this.fireAlert({
          title: "Nenhum Atendimento foi selecionado!",
          tipo: "error",
          routeName: "atendimento",
          timer: 5000,
        });
        this.$store.dispatch("MudarPreloader", false);
        return;
      }
      let data = { atendimento_id: atendimento_id };

      this.lista_ocorrencias();
      this.listar_requerimentos();

      await this.$store
        .dispatch("atendimento/show_atendimento", data)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
      this.calcularIdade()
      console.log("created", this.dados_modais)
      // if(this.dados_modais) {
      //   console.log('existe',this.dados_modais)
      //   if (this.dados_modais.fechar === true) {
      //     // this.modal_ocorrencia.tipo_ocorrencia = this.dados_modais.tipo
      //     this.modal_ocorrencia.show = false
      //     console.log(3)
      //   }
      // }else{
      //   console.log('nao existe')
      // }
    },

    async lista_ocorrencias() {
      const { atendimento_id } = this.$route.params;

      if (!atendimento_id) {
        this.fireAlert({
          title: "Nenhum Atendimento foi selecionado!",
          tipo: "error",
          routeName: "atendimento",
          timer: 5000,
        });

        return;
      }
      let data = { atendimento_id: atendimento_id };
      await this.$store.dispatch(
        "atendimento/lista_atendimento_ocorrencias",
        data
      );
      this.$store.dispatch("configEmpresa/atualizar", "");

      console.log("listando ocorrencias", this.dados_modais)
    },


  },

};
</script>
